export default function LogoIcon2({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 17.28C0 9.13413 0 5.06119 2.5306 2.5306C5.06119 0 9.13413 0 17.28 0H18.72C26.8659 0 30.9388 0 33.4694 2.5306C36 5.06119 36 9.13413 36 17.28V18.72C36 26.8659 36 30.9388 33.4694 33.4694C30.9388 36 26.8659 36 18.72 36H17.28C9.13413 36 5.06119 36 2.5306 33.4694C0 30.9388 0 26.8659 0 18.72V17.28Z'
        fill='#8D34F0'
      />
      <rect
        x='36'
        y='36'
        width='36'
        height='36'
        rx='8.64'
        transform='rotate(-180 36 36)'
        fill='url(#gradient0)'
      />
      <path
        d='M36 17.28C36 9.13413 36 5.06119 33.4694 2.5306C30.9388 -1.02997e-06 26.8659 -7.11379e-07 18.72 7.55251e-10L17.28 1.26644e-07C9.13413 8.38779e-07 5.06119 1.20163e-06 2.5306 2.5306C-1.02997e-06 5.0612 -7.11379e-07 9.13413 7.55231e-10 17.28L1.26644e-07 18.72C8.38779e-07 26.8659 1.20163e-06 30.9388 2.5306 33.4694C5.0612 36 9.13413 36 17.28 36H18.72C26.8659 36 30.9388 36 33.4694 33.4694C36 30.9388 36 26.8659 36 18.72V17.28Z'
        fill='url(#gradient1)'
      />
      <path
        d='M13.684 29.8129C12.6911 30.5288 11.2763 30.3306 10.6237 29.3264C8.44679 25.9768 7.19922 22.0787 7.19922 17.9185C7.19922 13.8313 8.40336 9.99712 10.5098 6.68756C11.1496 5.68235 12.5533 5.46894 13.5495 6.17186C13.733 6.30129 13.9228 6.4357 14.1194 6.57491C14.7878 7.04811 14.9263 7.9342 14.4921 8.60694C12.7255 11.3443 11.7139 14.5251 11.7139 17.9185C11.7139 21.3406 12.7427 24.5466 14.5372 27.2996C15.003 28.0142 14.8656 28.959 14.1591 29.4697C13.9962 29.5875 13.8378 29.7019 13.684 29.8129Z'
        fill='url(#gradient2)'
      />
      <path
        d='M20.1108 10.8169C19.0626 10.0747 17.5519 10.2954 16.9716 11.4024C15.9354 13.3792 15.356 15.588 15.356 17.9185C15.356 20.2977 15.9598 22.55 17.0371 24.5581C17.6287 25.6607 19.1424 25.8679 20.1833 25.1156L20.6496 24.7786C21.6103 24.0842 21.8119 22.8167 21.3558 21.7607C20.8412 20.5693 20.559 19.2735 20.559 17.9185C20.559 16.5966 20.8276 15.3312 21.3184 14.164C21.7646 13.1031 21.5502 11.836 20.5814 11.15L20.1108 10.8169Z'
        fill='url(#gradient3)'
      />
      <path
        d='M27.2587 15.765C26.1607 14.9593 24.4589 15.3721 24.2146 16.662C24.1373 17.0704 24.097 17.4902 24.097 17.9185C24.097 18.3656 24.1409 18.8035 24.225 19.2289C24.4802 20.5186 26.1886 20.9178 27.2796 20.1012C28.112 19.4782 28.8039 18.7593 28.7992 17.9245C28.7945 17.091 28.0962 16.3794 27.2587 15.765Z'
        fill='url(#gradient4)'
      />
      <defs>
        <linearGradient
          id='gradient0'
          x1='55.62'
          y1='30.78'
          x2='57.06'
          y2='51.84'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4B7FF' stopOpacity='0.7' />
          <stop offset='0.495' stopColor='#F4B7FF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='gradient1'
          x1='6.66'
          y1='8.89367e-07'
          x2='25.2'
          y2='23.58'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4B7FF' stopOpacity='0.7' />
          <stop offset='0.95' stopColor='#F4B7FF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='gradient2'
          x1='17.9992'
          y1='4.93943'
          x2='17.9992'
          y2='31.0746'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#EFEFEF' />
        </linearGradient>
        <linearGradient
          id='gradient3'
          x1='17.9992'
          y1='4.93943'
          x2='17.9992'
          y2='31.0746'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#EFEFEF' />
        </linearGradient>
        <linearGradient
          id='gradient4'
          x1='17.9992'
          y1='4.93943'
          x2='17.9992'
          y2='31.0746'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#EFEFEF' />
        </linearGradient>
      </defs>
    </svg>
  );
}
