import * as yup from 'yup';
import { inputs, MIN_LENGTH, EMAIL_REGEX } from './common';

export const MAX_LENGTH = 46;
export const NAME_MAX_LENGTH = 255;
export const EMAIL_MAX_LENGTH = 255;
export const MAX_ADDRESS_LENGTH = 100;
export const NOTES_MAX_LENGTH = 200;

export const contactInputs = {
  name: yup.string().trim().max(NAME_MAX_LENGTH, `Shouldn't exceed ${NAME_MAX_LENGTH} characters`),
  number: yup.string().required('Required'),
  email: yup
    .string()
    .matches(EMAIL_REGEX, 'Email must be a valid email')
    .email('Email must be a valid email')
    .max(EMAIL_MAX_LENGTH, `Shouldn't exceed ${EMAIL_MAX_LENGTH} characters`),
  company: yup.string().trim().max(MAX_LENGTH, `Shouldn't exceed ${MAX_LENGTH} characters`),
  address: yup
    .string()
    .trim()
    .max(MAX_ADDRESS_LENGTH, `Shouldn't exceed ${MAX_ADDRESS_LENGTH} characters`),
  note: yup
    .string()
    .min(MIN_LENGTH, `Should contain atleast ${MIN_LENGTH} characters`)
    .max(NOTES_MAX_LENGTH, `Shouldn't exceed ${NOTES_MAX_LENGTH} characters`),
};
