export const KrispcallDark = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width='140'
    height='50'
    viewBox='0 0 140 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M37.2583 34.563V15.9549H39.9364V24.0361L47.4351 15.9549H50.7827L43.8197 23.3449L50.9166 34.563H47.6493L41.9182 25.4184L39.9364 27.5185V34.563H37.2583Z'
      fill='url(#paint0_linear_2149_2)'
    />
    <path
      d='M52.83 34.563V21.1651H55.2403L55.4813 23.6905C55.7849 23.088 56.1776 22.5829 56.6597 22.1753C57.1418 21.75 57.7042 21.4221 58.3469 21.1917C59.0075 20.9613 59.7574 20.8461 60.5965 20.8461V23.6639H59.6324C59.0789 23.6639 58.5522 23.7348 58.0523 23.8766C57.5524 24.0007 57.1061 24.2222 56.7133 24.5412C56.3383 24.8602 56.0437 25.2944 55.8295 25.8437C55.6152 26.3931 55.5081 27.0754 55.5081 27.8906V34.563H52.83Z'
      fill='url(#paint1_linear_2149_2)'
    />
    <path
      d='M62.7078 34.563V21.1651H65.3859V34.563H62.7078ZM64.0737 18.6397C63.5559 18.6397 63.1274 18.4802 62.7882 18.1613C62.4668 17.8423 62.3061 17.4346 62.3061 16.9384C62.3061 16.4599 62.4668 16.0701 62.7882 15.7688C63.1274 15.4498 63.5559 15.2903 64.0737 15.2903C64.5736 15.2903 64.9931 15.4498 65.3324 15.7688C65.6716 16.0701 65.8412 16.4599 65.8412 16.9384C65.8412 17.4346 65.6716 17.8423 65.3324 18.1613C64.9931 18.4802 64.5736 18.6397 64.0737 18.6397Z'
      fill='url(#paint2_linear_2149_2)'
    />
    <path
      d='M73.9023 34.882C72.7597 34.882 71.7598 34.6959 70.9028 34.3237C70.0458 33.9516 69.3674 33.4288 68.8675 32.7553C68.3676 32.0819 68.064 31.2933 67.9569 30.3894H70.6886C70.7779 30.8148 70.9475 31.2047 71.1974 31.5591C71.4652 31.9135 71.8223 32.1971 72.2687 32.4098C72.7329 32.6224 73.2774 32.7288 73.9023 32.7288C74.4915 32.7288 74.9736 32.649 75.3485 32.4895C75.7413 32.3123 76.0269 32.0819 76.2055 31.7983C76.384 31.4971 76.4733 31.1781 76.4733 30.8414C76.4733 30.3451 76.3483 29.973 76.0984 29.7249C75.8663 29.459 75.5092 29.2552 75.0271 29.1135C74.5629 28.954 74.0005 28.8122 73.3399 28.6881C72.715 28.5818 72.108 28.44 71.5188 28.2628C70.9475 28.0679 70.4297 27.8286 69.9655 27.5451C69.5191 27.2615 69.1621 26.9071 68.8943 26.4817C68.6264 26.0387 68.4925 25.4982 68.4925 24.8602C68.4925 24.0981 68.6979 23.4158 69.1085 22.8133C69.5191 22.193 70.0994 21.7145 70.8493 21.3778C71.617 21.0234 72.5186 20.8461 73.5542 20.8461C75.0539 20.8461 76.259 21.2006 77.1696 21.9095C78.0802 22.6183 78.6158 23.6196 78.7765 24.9133H76.1787C76.1073 24.3108 75.8395 23.85 75.3753 23.531C74.9111 23.1943 74.2951 23.0259 73.5274 23.0259C72.7597 23.0259 72.1705 23.1766 71.7598 23.4779C71.3492 23.7791 71.1439 24.1779 71.1439 24.6741C71.1439 24.9931 71.2599 25.2766 71.492 25.5248C71.7241 25.7729 72.0633 25.9855 72.5097 26.1627C72.9739 26.3222 73.5363 26.4729 74.1969 26.6147C75.1432 26.7919 75.9912 27.0134 76.7411 27.2792C77.491 27.5451 78.0891 27.9349 78.5354 28.4489C78.9818 28.9628 79.205 29.6983 79.205 30.6553C79.2228 31.4882 79.0086 32.2237 78.5622 32.8617C78.1337 33.4997 77.5178 33.9959 76.7143 34.3503C75.9287 34.7048 74.9914 34.882 73.9023 34.882Z'
      fill='url(#paint3_linear_2149_2)'
    />
    <path
      d='M81.8226 40.4113V21.1651H84.2329L84.5007 23.2386C84.7864 22.831 85.1434 22.45 85.5719 22.0955C86.0004 21.7234 86.5093 21.4221 87.0985 21.1917C87.7055 20.9613 88.4107 20.8461 89.2142 20.8461C90.4997 20.8461 91.6245 21.1563 92.5886 21.7765C93.5705 22.3968 94.3293 23.2386 94.865 24.3019C95.4184 25.3475 95.6952 26.5438 95.6952 27.8906C95.6952 29.2375 95.4184 30.4426 94.865 31.5059C94.3115 32.5515 93.5438 33.3756 92.5618 33.9782C91.5977 34.5807 90.4729 34.882 89.1874 34.882C88.134 34.882 87.2056 34.6782 86.4022 34.2706C85.5987 33.8452 84.9649 33.2604 84.5007 32.5161V40.4113H81.8226ZM88.7589 32.5693C89.5802 32.5693 90.3033 32.3743 90.9282 31.9844C91.5709 31.5945 92.0708 31.0452 92.4279 30.3363C92.8028 29.6274 92.9903 28.8033 92.9903 27.8641C92.9903 26.9248 92.8028 26.1096 92.4279 25.4184C92.0708 24.7095 91.5709 24.1602 90.9282 23.7703C90.3033 23.3627 89.5802 23.1589 88.7589 23.1589C87.9197 23.1589 87.1788 23.3627 86.5361 23.7703C85.9112 24.1602 85.4202 24.7095 85.0631 25.4184C84.706 26.1096 84.5275 26.9248 84.5275 27.8641C84.5275 28.8033 84.706 29.6274 85.0631 30.3363C85.4202 31.0452 85.9112 31.5945 86.5361 31.9844C87.1788 32.3743 87.9197 32.5693 88.7589 32.5693Z'
      fill='url(#paint4_linear_2149_2)'
    />
    <path
      d='M106.642 34.882C104.803 34.882 103.214 34.4832 101.875 33.6857C100.554 32.8705 99.5275 31.7452 98.7955 30.3097C98.0813 28.8565 97.7243 27.1729 97.7243 25.2589C97.7243 23.3627 98.0813 21.6879 98.7955 20.2347C99.5275 18.7815 100.554 17.6562 101.875 16.8587C103.214 16.0435 104.803 15.6359 106.642 15.6359C108.838 15.6359 110.615 16.1587 111.972 17.2043C113.347 18.2499 114.221 19.7208 114.596 21.617H111.65C111.383 20.5183 110.829 19.641 109.99 18.9853C109.169 18.3296 108.053 18.0018 106.642 18.0018C105.375 18.0018 104.277 18.2942 103.348 18.879C102.42 19.4638 101.706 20.2968 101.206 21.3778C100.706 22.4588 100.456 23.7525 100.456 25.2589C100.456 26.7653 100.706 28.0679 101.206 29.1666C101.706 30.2477 102.42 31.0806 103.348 31.6654C104.277 32.2325 105.375 32.5161 106.642 32.5161C108.053 32.5161 109.169 32.206 109.99 31.5857C110.829 30.9477 111.383 30.0882 111.65 29.0071H114.596C114.221 30.8502 113.347 32.2946 111.972 33.3402C110.615 34.368 108.838 34.882 106.642 34.882Z'
      fill='url(#paint5_linear_2149_2)'
    />
    <path
      d='M121.603 34.882C120.496 34.882 119.577 34.6959 118.845 34.3237C118.113 33.9516 117.568 33.4554 117.211 32.8351C116.854 32.1971 116.675 31.5148 116.675 30.7882C116.675 29.9021 116.908 29.1489 117.372 28.5286C117.836 27.8906 118.497 27.4033 119.354 27.0666C120.211 26.7298 121.237 26.5615 122.433 26.5615H125.942C125.942 25.7817 125.826 25.1349 125.593 24.6209C125.361 24.107 125.013 23.726 124.549 23.4779C124.103 23.212 123.531 23.0791 122.835 23.0791C122.032 23.0791 121.344 23.2741 120.773 23.6639C120.202 24.0361 119.845 24.5943 119.702 25.3387H117.024C117.131 24.3994 117.452 23.6019 117.988 22.9462C118.541 22.2728 119.246 21.7588 120.103 21.4044C120.96 21.0322 121.871 20.8461 122.835 20.8461C124.103 20.8461 125.165 21.0677 126.022 21.5107C126.879 21.9538 127.522 22.5829 127.95 23.3981C128.397 24.1956 128.62 25.1526 128.62 26.2691V34.563H126.29L126.076 32.3034C125.879 32.6579 125.647 32.9946 125.379 33.3136C125.111 33.6326 124.79 33.9073 124.415 34.1377C124.058 34.368 123.638 34.5453 123.156 34.6693C122.692 34.8111 122.174 34.882 121.603 34.882ZM122.112 32.7288C122.683 32.7288 123.201 32.6136 123.665 32.3832C124.129 32.1528 124.522 31.8427 124.844 31.4528C125.183 31.0452 125.433 30.5933 125.593 30.097C125.772 29.5831 125.87 29.0603 125.888 28.5286V28.4489H122.701C121.933 28.4489 121.309 28.5464 120.826 28.7413C120.362 28.9185 120.023 29.1666 119.809 29.4856C119.595 29.8046 119.487 30.1768 119.487 30.6021C119.487 31.0452 119.586 31.4262 119.782 31.7452C119.996 32.0465 120.3 32.2857 120.693 32.4629C121.085 32.6401 121.558 32.7288 122.112 32.7288Z'
      fill='url(#paint6_linear_2149_2)'
    />
    <path d='M131.502 34.563V15.4232H134.181V34.563H131.502Z' fill='url(#paint7_linear_2149_2)' />
    <path d='M137.322 34.563V15.4232H140V34.563H137.322Z' fill='url(#paint8_linear_2149_2)' />
    <path
      d='M0 24.3226C0 17.9358 0 14.7424 1.98412 12.7583C3.96823 10.7742 7.16162 10.7742 13.5484 10.7742H14.6774C21.0642 10.7742 24.2576 10.7742 26.2417 12.7583C28.2258 14.7424 28.2258 17.9358 28.2258 24.3226V25.4516C28.2258 31.8384 28.2258 35.0317 26.2417 37.0159C24.2576 39 21.0642 39 14.6774 39H13.5484C7.16162 39 3.96823 39 1.98412 37.0159C0 35.0317 0 31.8384 0 25.4516V24.3226Z'
      fill='#8D34F0'
    />
    <rect
      x='28.2256'
      y='39'
      width='28.2258'
      height='28.2258'
      rx='6.77419'
      transform='rotate(-180 28.2256 39)'
      fill='url(#paint9_linear_2149_2)'
    />
    <path
      d='M28.2258 24.3226C28.2258 17.9358 28.2258 14.7424 26.2417 12.7583C24.2576 10.7742 21.0642 10.7742 14.6774 10.7742L13.5484 10.7742C7.16161 10.7742 3.96823 10.7742 1.98411 12.7583C-8.07547e-07 14.7424 -5.57757e-07 17.9358 5.92139e-10 24.3226L9.92953e-08 25.4516C6.57645e-07 31.8384 9.42138e-07 35.0317 1.98412 37.0159C3.96823 39 7.16162 39 13.5484 39H14.6774C21.0642 39 24.2576 39 26.2417 37.0159C28.2258 35.0317 28.2258 31.8384 28.2258 25.4516V24.3226Z'
      fill='url(#paint10_linear_2149_2)'
    />
    <path
      d='M10.7294 34.1488C9.95092 34.7102 8.84167 34.5547 8.32997 33.7674C6.62318 31.1412 5.64502 28.0849 5.64502 24.823C5.64502 21.6185 6.58912 18.6123 8.24072 16.0174C8.74236 15.2293 9.84292 15.062 10.624 15.6131C10.7678 15.7146 10.9166 15.82 11.0708 15.9291C11.5948 16.3001 11.7034 16.9949 11.363 17.5223C9.97788 19.6686 9.18475 22.1625 9.18475 24.823C9.18475 27.5062 9.9914 30.0198 11.3984 32.1783C11.7636 32.7386 11.6559 33.4794 11.1019 33.8797C10.9742 33.9721 10.85 34.0618 10.7294 34.1488Z'
      fill='url(#paint11_linear_2149_2)'
    />
    <path
      d='M15.7683 19.255C14.9465 18.6731 13.7621 18.8461 13.3071 19.7141C12.4946 21.264 12.0403 22.9958 12.0403 24.823C12.0403 26.6885 12.5138 28.4544 13.3584 30.0288C13.8222 30.8933 15.0091 31.0558 15.8252 30.4659L16.1908 30.2017C16.944 29.6573 17.1021 28.6635 16.7445 27.8355C16.3411 26.9014 16.1197 25.8855 16.1197 24.823C16.1197 23.7866 16.3304 22.7945 16.7152 21.8793C17.065 21.0475 16.8969 20.0541 16.1373 19.5162L15.7683 19.255Z'
      fill='url(#paint12_linear_2149_2)'
    />
    <path
      d='M21.3727 23.1346C20.5118 22.5029 19.1774 22.8265 18.9859 23.8379C18.9253 24.1581 18.8937 24.4873 18.8937 24.823C18.8937 25.1736 18.9281 25.5169 18.9941 25.8505C19.1942 26.8617 20.5336 27.1746 21.3891 26.5344C22.0417 26.0459 22.5841 25.4823 22.5805 24.8278C22.5768 24.1742 22.0293 23.6164 21.3727 23.1346Z'
      fill='url(#paint13_linear_2149_2)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint7_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint8_linear_2149_2'
        x1='88.2177'
        y1='39.175'
        x2='87.574'
        y2='17.8296'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8548FF' />
        <stop offset='1' stopColor='#EA66FF' />
      </linearGradient>
      <linearGradient
        id='paint9_linear_2149_2'
        x1='43.6087'
        y1='34.9073'
        x2='44.7377'
        y2='51.4194'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B7FF' stopOpacity='0.7' />
        <stop offset='0.495' stopColor='#F4B7FF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint10_linear_2149_2'
        x1='5.22178'
        y1='10.7742'
        x2='19.7581'
        y2='29.2621'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F4B7FF' stopOpacity='0.7' />
        <stop offset='0.95' stopColor='#F4B7FF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint11_linear_2149_2'
        x1='14.1128'
        y1='14.6468'
        x2='14.1128'
        y2='35.1381'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='#EFEFEF' />
      </linearGradient>
      <linearGradient
        id='paint12_linear_2149_2'
        x1='14.1128'
        y1='14.6468'
        x2='14.1128'
        y2='35.1381'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='#EFEFEF' />
      </linearGradient>
      <linearGradient
        id='paint13_linear_2149_2'
        x1='14.1128'
        y1='14.6468'
        x2='14.1128'
        y2='35.1381'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='#EFEFEF' />
      </linearGradient>
    </defs>
  </svg>
);
