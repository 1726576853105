import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { activeCallConversationData, smsLimitExceededWarning } from 'services/apollo/reactiveVars';
import { useTwilioContext } from 'lib/twilio';
import { useSmsHelper } from 'components/organisms/conversation/client-to-agent/conversation-box/hooks/useSmsHelper';
import useCallMessageEligibilityCheck, {
  EligibilityCheckPayload,
} from 'hooks/useCallMessageEligibilityCheck';

import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';
import { usePhoneMutation } from '../hooks/usePhoneMutation';
import useCallWidgetDrawerContext from '../hooks/useCallWidgetDrawerContext';

type IMessageInputForm = {
  message: string;
};

export function useDeclineWithMessage() {
  const { closeDrawer } = useCallWidgetDrawerContext();
  const {
    state: { connection },
  } = useTwilioContext();
  const callerChannelId = connection?.customParameters?.get('channel_sid');
  const { channelInfo } = useActiveCallWidgetContext();
  const { t } = useTranslation();
  const { checkCallMessageEligibility } = useCallMessageEligibilityCheck();
  const { isContactSmsSupported, isAgentSmsSupported, isSmsSupportedIntl } = useSmsHelper({
    channelId: callerChannelId,
  });
  const smsLimitWarningVisible = useReactiveVar<any>(smsLimitExceededWarning);

  const showSmsLimitWarning = () => {
    // Show sms limit exceed warning modal for kyc unverified users
    smsLimitExceededWarning(!smsLimitWarningVisible);
  };

  const { updateRejectConversation, sendMessage } = usePhoneMutation({
    showSmsLimitWarning,
  });

  const conversationDataRef: any = useRef();
  const conversationData = useReactiveVar(activeCallConversationData);
  conversationDataRef.current = conversationData;

  const messageInput = {
    message: yup.string().required('Custom Message is required'),
  };

  const schema = yup.object().shape({
    message: messageInput.message,
  });
  const formMethods = useForm<IMessageInputForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });
  const { setError, clearErrors, handleSubmit } = formMethods;

  const handleMessageSubmit = async (val: IMessageInputForm) => {
    const message = val?.message?.trim();
    if (!message) {
      setError('message', {
        type: 'manual',
        message: 'Required',
      });
      return;
    }
    const callerNumber = `+${connection?.customParameters
      ?.get('from')
      ?.trim()
      ?.replace(/\+/g, '')}`;
    const eligibilityCheckPayload: EligibilityCheckPayload = {
      originationNumber: channelInfo?.number || '',
      destinationNumber: callerNumber || '',
      eventType: 'Message',
    };
    const isEligibleForMessage = await checkCallMessageEligibility(eligibilityCheckPayload);
    if (!isEligibleForMessage) return;
    if (!isAgentSmsSupported(channelInfo)) return;
    if (!isContactSmsSupported(callerNumber)) return;
    if (!isSmsSupportedIntl(callerNumber, channelInfo?.number)) return;
    connection?.reject();
    if (conversationDataRef.current?.id) updateRejectConversation(conversationDataRef.current.id);
    await sendMessage({
      variables: {
        data: {
          conversationType: 'Message',
          content: {
            body: message,
            files: [],
          },
          contact: callerNumber,
          channel: callerChannelId,
        },
      },
    });
    closeDrawer();
  };

  const onSelectMessageTemplate = (message: string) => {
    handleMessageSubmit({ message });
    clearErrors();
  };

  return {
    formMethods,
    onSelectMessageTemplate,
    handleSendMessage: handleSubmit(handleMessageSubmit),
  };
}
