import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_FREE_USAGE_BALANCE } from 'graphql/foundation';

export function useFreeUsageBalanceQuery() {
  const [
    loadFreeUsageBalance,
    { data: workspaceData, loading: loadingFreeUsageBalance },
  ] = useLazyQuery(GET_FREE_USAGE_BALANCE, {
    fetchPolicy: 'no-cache',
  });

  const fetchFreeUsageBalanceData = async () => {
    loadFreeUsageBalance({
      variables: {},
    });
  };

  const freeUsageBalanceData: any = useMemo(
    () => workspaceData?.workspace?.data?.freeUsageBalance ?? {},
    [workspaceData],
  );
  return {
    fetchFreeUsageBalanceData,
    freeUsageBalanceData,
    loadingFreeUsageBalance,
  };
}
